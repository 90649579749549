import { Splide } from '@splidejs/react-splide';
import { useRef, useState } from 'react';
import Carrousel from '@/molecules/Carrousel/components/elements';
import SlideTabIcon from 'components/widgets/edito/WideSlideshow/SlideTabIcon';
import { CMSCallToActionType, CmsImageType } from 'utils/cms/types';
import TitleBox from '@/molecules/titles/TitleBox';
import { SIZE } from '@/types/utils';

export type WideSlideShowWidgetSlide = {
  image: CmsImageType;
  icon?: string;
  title?: string;
  description?: string;
  cta?: CMSCallToActionType;
};

export type WideSlideShowWidgetProps = {
  slides: WideSlideShowWidgetSlide[];
  title?: string | null;
  cta: CMSCallToActionType;
};

const WideSlideShowWidget = ({ slides, title, cta }: WideSlideShowWidgetProps) => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const carouselRef = useRef<Splide>(null);
  const goToSlide = (index: number) => {
    carouselRef.current?.splide?.Components.Controller.go(index);
  };

  return (
    <div
      className="w-full relative 
    bg-[url('/bg/UserDialog.png')]
    before:bg-gradient-to-b before:to-transparent before:absolute before:w-full before:rounded-2xl before:h-20 before:block before:top-0 before:z-1 before:left-0
    after:bg-gradient-to-t  after:to-transparent after:absolute after:w-full after:rounded-2xl after:h-20 after:block after:bottom-0 after:z-1 after:left-0
    before:from-neutral-0 after:from-neutral-0 
    "
    >
      <div className="w-full pt-6 md:pt-8 pb-8 md:pb-12 px-4 flex flex-col gap-8 md:gap-12 relative z-3">
        {!!title && (
          <div className="max-w-1200 w-full m-auto">
            <TitleBox
              buttonProps={
                cta.link
                  ? {
                      href: cta.link.url,
                      children: cta.link.title,
                      variant: cta.variant,
                      size: SIZE.SM,
                      icon: cta.icon,
                    }
                  : undefined
              }
            >
              {title}
            </TitleBox>
          </div>
        )}

        <Carrousel.Root ref={carouselRef} onChange={setSelectedSlide}>
          <div className="flex flex-col gap-4 md:gap-6 items-center  max-w-1200 mx-auto">
            <Carrousel.Body className="!mb-0">
              <Carrousel.Arrow arrowDirection="prev" />
              <Carrousel.Content>
                {slides.map((slide) => (
                  <Carrousel.Slide key={slide.title}>
                    <img
                      key={slide.image.id}
                      alt={slide.image.alt}
                      className="w-full rounded-2xl"
                      src={slide.image.url}
                    />
                  </Carrousel.Slide>
                ))}
              </Carrousel.Content>
              <Carrousel.Arrow arrowDirection="next" />
            </Carrousel.Body>
            <div className="flex gap-2">
              {slides.map((slide, index) => {
                if (slide.icon && slide.title) {
                  return (
                    <SlideTabIcon
                      key={slide.title}
                      active={selectedSlide === index}
                      icon={slide.icon}
                      label={slide.title}
                      onClick={() => goToSlide(index)}
                    />
                  );
                }

                return null;
              })}
            </div>
            <p className="text-primary-500 title-3 md:hidden">{slides[selectedSlide].title}</p>
          </div>
          <Carrousel.Pagination className="hidden" />
        </Carrousel.Root>
      </div>
    </div>
  );
};

export default WideSlideShowWidget;
