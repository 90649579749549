import Button, { ButtonProps } from '@/atoms/Button';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  buttonProps?: ButtonProps;
};

const TitleBox = ({ children, buttonProps }: Props) => (
  <div className="px-4 md:px-0">
    <div className="flex justify-between mb-2 items-center flex-wrap">
      <p className="text-neutral-900 title-2">{children}</p>
      {!!buttonProps && <Button {...buttonProps} />}
    </div>
    <div className="h-1 bg-gradient-to-l from-transparent to-transparent via-primary-500 w-full " />
  </div>
);

export default TitleBox;
