/* eslint-disable import/prefer-default-export */
import { Options, Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import clx from 'classnames';
import { ComponentProps, PropsWithChildren, forwardRef } from 'react';
import Button, { BUTTON_VARIANTS, ButtonProps } from '@/atoms/Button';
import { SIZE } from 'types/utils';

type CarrouselRootProps = {
  options?: Options;
  className?: string;
  ariaLabel?: string;
  onChange?: (index: number) => void;
};

export const CarrouselRoot = forwardRef<Splide, PropsWithChildren<CarrouselRootProps>>(
  ({ options, children, onChange, className, ariaLabel }, ref) => (
    <Splide
      ref={ref}
      aria-label={ariaLabel}
      className={className}
      hasTrack={false}
      onActive={(_, slide) => onChange?.(slide.index)}
      options={{
        gap: '32px',
        free: true,
        classes: {
          page: clx(
            'splide__pagination__page h-3 w-3 block rounded-full',
            'bg-neutral-900 transition-all opacity-30 hover:opacity-100 aria-selected:opacity-100 aria-selected:w-5 aria-selected:bg-primary-500 hover:bg-primary-300 transition-all ',
          ),
        },
        ...options,
      }}
    >
      {children}
    </Splide>
  ),
);
CarrouselRoot.displayName = 'CarrouselRoot';

export const CarrouselBody = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={clx('flex items-center mb-6 gap-6', className)} {...props} />
);

export type CarrouselArrowProps = ButtonProps & {
  arrowDirection: 'prev' | 'next';
};

export const CarrouselArrow = ({ arrowDirection, className, ...props }: CarrouselArrowProps & { children?: never }) => (
  <div className="splide__arrows hidden md:block">
    <Button
      size={SIZE.MD}
      variant={BUTTON_VARIANTS.PRIMARY}
      {...props}
      className={clx(`splide__arrow splide__arrow--${arrowDirection}`, className)}
      icon={`fa-solid fa-arrow-${arrowDirection === 'prev' ? 'left' : 'right'}`}
    />
  </div>
);

export const CarrouselContent = ({ children, className }: PropsWithChildren<ComponentProps<typeof SplideTrack>>) => (
  <SplideTrack className={clx('grow', className)}>{children}</SplideTrack>
);

export const CarrouselSlide = (props: PropsWithChildren<ComponentProps<typeof SplideSlide>>) => (
  <SplideSlide {...props} />
);

export const CarrouselPagination = ({ className }: ComponentProps<'ul'>) => (
  <ul className={clx('splide__pagination flex gap-1 p-2', className)} />
);

const Carrousel = {
  Root: CarrouselRoot,
  Content: CarrouselContent,
  Arrow: CarrouselArrow,
  Body: CarrouselBody,
  Slide: CarrouselSlide,
  Pagination: CarrouselPagination,
};

export default Carrousel;
