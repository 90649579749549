import clx from 'classnames';

type Props = {
  label: string;
  icon: string;
  onClick?: () => void;
  active?: boolean;
};

const SlideTabIcon = ({ label, icon, onClick, active }: Props) => (
  <button
    className={clx(
      'flex flex-col gap-2 w-fit md:w-[124px] items-center hover:opacity-100  cursor-pointer transition-all',
      active ? 'opacity-100 text-primary-500' : 'opacity-60 text-neutral-500',
    )}
    onClick={() => onClick?.()}
    type="button"
  >
    <div className="border-4 border-current rounded-full aspect-square p-2 flex items-center justify-center">
      <i className={clx(icon, 'fa-fw text-current text-xl md:text-2xl')} />
    </div>
    <span className="text-current hidden md:block uppercase text-base font-sans font-bold">{label}</span>
  </button>
);

export default SlideTabIcon;
